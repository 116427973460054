var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('EventVenueAddExisting',{attrs:{"all-venues":_vm.allVenues},on:{"cancel":function($event){_vm.showExistingModal = false},"venues-added":_vm.handleVenuesAdded},model:{value:(_vm.showExistingModal),callback:function ($$v) {_vm.showExistingModal=$$v},expression:"showExistingModal"}}),_c('card-header-filters',{attrs:{"title":"Venues / Facilities"},on:{"add":function($event){_vm.showExistingModal = true}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('b-dropdown',{staticClass:"button-dropdown ml-1",attrs:{"variant":"primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"mr-sm-50",attrs:{"icon":"PlusIcon"}}),_vm._v(" Add ")]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'organization.venue.create',
            query: { sport: _vm.$store.state.event.event.sport, event: _vm.$route.params.event }
          }}},[_vm._v(" New ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.showExistingModal = true}}},[_vm._v(" Existing ")])],1)]},proxy:true}])}),_c('table-list',{attrs:{"show-empty":"","primary-key":"id","item-name":"venue","delete-button-text":"Remove from League","fields":_vm.fields,"items":_vm.venues,"endpoints":{ delete: ("/events/" + (_vm.$route.params.event) + "/venues") },"delete-modal":function (ref) {
              var item = ref.item;

              return ({
      title: 'Remove From League',
      text: 'Are you sure you want to remove this?',
      deleteButtonText: 'Remove from League',
      flashMessage: ((item.name) + " has been successfully removed!"),
    });
}},on:{"link-clicked":function($event){return _vm.$router.push({
      name: 'organization.venue.information',
      params: { venue: $event.id },
      query: { sport: _vm.event.sport, event: _vm.$route.params.event }
    })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }