<template>
  <b-card no-body>
    <EventVenueAddExisting
      v-model="showExistingModal"
      :all-venues="allVenues"
      @cancel="showExistingModal = false"
      @venues-added="handleVenuesAdded"
    />

    <card-header-filters
      title="Venues / Facilities"
      @add="showExistingModal = true"
    >
      <template #button>
        <b-dropdown
          class="button-dropdown ml-1"
          variant="primary"
        >
          <template #button-content>
            <feather-icon
              icon="PlusIcon"
              class="mr-sm-50"
            />
            Add
          </template>
          <b-dropdown-item
            :to="{
              name: 'organization.venue.create',
              query: { sport: $store.state.event.event.sport, event: $route.params.event }
            }"
          >
            New
          </b-dropdown-item>

          <b-dropdown-item @click="showExistingModal = true">
            Existing
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </card-header-filters>

    <!-- Venues List -->
    <table-list
      show-empty
      primary-key="id"
      item-name="venue"
      delete-button-text="Remove from League"
      :fields="fields"
      :items="venues"
      :endpoints="{ delete: `/events/${$route.params.event}/venues` }"
      :delete-modal="({ item }) => ({
        title: 'Remove From League',
        text: 'Are you sure you want to remove this?',
        deleteButtonText: 'Remove from League',
        flashMessage: `${item.name} has been successfully removed!`,
      })"
      @link-clicked="$router.push({
        name: 'organization.venue.information',
        params: { venue: $event.id },
        query: { sport: event.sport, event: $route.params.event }
      })"
    />
  </b-card>
</template>

<script>
import {
  BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import EventVenueAddExisting from '@/views/events/venues/EventVenueAddExisting.vue'
import TableList from '@/components/TableList.vue'

import store from '@/store'
import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventVenues',
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    CardHeaderFilters,
    EventVenueAddExisting,
    TableList,
  },
  data() {
    return {
      allVenues: [],
      showExistingModal: false,
      fields: [
        'name',
        'abbreviation',
        {
          key: 'address',
          formatter: address => `${address.street_address}, ${address.city}, ${address.state} ${address.postal_code}`,
        },
      ],
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/venues', ['venues']),
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('event/venues/getVenues', { query: 'events=true' })
      .then(data => {
        next(vm => {
          vm.allVenues = data
          // vm.allVenues = response.data.data.map(venue => ({
          //   ...venue,
          //   include: !!venue.events.filter(event => event.id === parseInt(to.params.event)).length,
          // }))
          store.commit(
            'event/venues/UPDATE_VENUES',
            vm.allVenues.filter(venue => !!venue.events.filter(event => event.id === parseInt(to.params.event)).length),
          )
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  methods: {
    handleVenuesAdded(venues) {
      // Resort venues alphabetically.
      venues.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })

      this.$store.commit('event/venues/UPDATE_VENUES', venues)
      this.showExistingModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.button-dropdown ::v-deep .dropdown-toggle::after {
  display: none;
}
</style>
