<template>
  <Modal
    title="Add Existing Venues"
    size="lg"
    :value="value"
    @show="handleShowModal"
  >
    <b-table
      outlined
      class="my-2"
      :fields="fields"
      :items="allVenues"
    >
      <template #head(name)>
        <b-form-checkbox
          class="venue-checkbox"
          :checked="allVenuesAreInEvent"
          @change="handleAllVenuesChange"
        >
          Name
        </b-form-checkbox>
      </template>

      <template #cell(name)="{ item }">
        <b-form-checkbox
          class="venue-checkbox"
          :checked="venueIsChecked(item)"
          @change="handleVenueChange(item, $event)"
        >
          {{ item.name }}
        </b-form-checkbox>
      </template>
    </b-table>

    <template #modal-footer>
      <submit-buttons
        :loading="form.isLoading"
        @save="editVenues"
        v-on="$listeners"
      />
    </template>
  </Modal>
</template>

<script>
import { BTable, BFormCheckbox } from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    BTable,
    BFormCheckbox,
    Modal,
    SubmitButtons,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    allVenues: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      venues: [],
      form: new Form({
        venues: [],
      }),
      fields: [
        'name',
      ],
    }
  },
  computed: {
    ...mapState('event/venues', {
      eventVenues: state => state.venues,
    }),
    allVenuesAreInEvent() {
      return this.allVenues.length === this.venues.length
    },
  },
  methods: {
    handleShowModal() {
      this.venues = JSON.parse(JSON.stringify(this.eventVenues))
    },
    handleAllVenuesChange(addAll) {
      this.venues = addAll ? JSON.parse(JSON.stringify(this.allVenues)) : []

      // this.venues = this.venues.map(venue => ({
      //   ...venue,
      //   include,
      // }))
    },
    handleVenueChange(venue, add) {
      if (add) {
        this.venues = [
          ...this.venues,
          venue,
        ]

        return
      }

      this.venues.splice(this.venues.findIndex(v => v.id === venue.id), 1)
    },
    venueIsChecked(venue) {
      return this.venues.find(v => v.id === venue.id) !== undefined
    },
    editVenues() {
      this.form.venues = this.venues.map(venue => venue.id)

      this.form.put(`events/${this.$route.params.event}/venues`)
        .then(() => {
          this.$emit('venues-added', this.venues)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .venue-checkbox ::v-deep label {
    margin-left: .8rem
  }
</style>
